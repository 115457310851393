import { post,noToken } from "../common/remote/base_api";
import noTokenBase from "../common/remote/base_api"

export const onLogin = (params) => {
  return noToken("/users/sos-admin-login/", params);
};
// export const tokenAPI = (params) => {
//   return post("/api/token/", params);
// };

