const initialState = {
  isLoading: false,
  visible: false,
  editVisible: false,
  request:[],
  requestList:[],
  eventId: null,
  viewId: null,
  visibleOtp:false,
  searchAllSkill: [],
  success:[],
  countryList:[],
  otpverified:[]
 
};

export default initialState;
