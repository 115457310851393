import React, { Suspense, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./presentation/common/Theme/themeVariables";
import { PrimaryLoadingIndicator } from "./presentation/common/UI/LoadingIndicator";

//import static css to override antd
import "./presentation/common/Style/style.css";

import ProtectedRoute from "./protectedRoute";
import { useUserStore } from "./presentation/Login/store";
import RequestExpanded from "./presentation/Request/RequestExpanded/RequestExpanded";

//component imports
const Login = React.lazy(() => import("./presentation/Login"));
const Home = React.lazy(() => import("./Home"));

function App() {
  const [path, setPath] = useState(window.location.pathname);

  // useEffect(() => {
  //   let unmounted = false;
  //   if (!unmounted) {
  //     setPath(window.location.pathname);
  //   }
  //   // eslint-disable-next-line no-return-assign
  //   return () => (unmounted = true);
  // }, [setPath]);

 

  return (
    <ThemeProvider
      theme={{ ...theme, rtl: false, topMenu: false, darkMode: false }}
    >
      <Router>
        <Suspense
          fallback={<PrimaryLoadingIndicator text="Loading..." isFullPage />}
        >
          <Switch>
       

          <Route path="/home" component={Home} />
            
              <Route path="/" component={Login} />
    
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
