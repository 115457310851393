// common utils here
export const logError = (type, data) => {
  console.log(type, data);
};

export const maxTextRefactor = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};

export const getPercentage = (value, total) => {
  if (value === 0) {
    return 0;
  } else {
    return ((value / total) * 100).toFixed(1);
  }
};
