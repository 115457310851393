import { post} from "../common/remote/base_api";


export const onAccept= (id) => {
  console.log(id,"hs")
    return post(`/sos/accepted/${id}/`);
  };

  export const onResendOtp= (params) => {
   
      return post(`/sos/resend-otp/` ,params);
    };

  
  export const onVerify= (params) => {
   
      return post(`/sos/enter-phone/`, params);
    };


    export const onVerifyOtp= (params) => {
   
      return post(`/sos/verify-otp/`, params);
    };



    
export const onReject= (id) => {
  console.log(id,"hs")
    return post(`/sos/rejected/${id}/`);
  };



  export const onCompleteRequest= (id) => {
    console.log(id,"hs")
      return post(`/sos/completed/${id}/`);
    };
  