const routes = {
  INITIAL: "/",
  LOGIN: "/login",
  DASHBOARD: "/home",
  REQUEST: "/home/request",
  REQUESTEXPANDED:"/home/requestexpanded",
  REQUESTEXPANDEDID:"/home/requestexpanded/:id",
  REQUESTCOMPLETED:"/home/requestcompleted",
  REQUESTCOMPLETEDID:"/home/requestcompleted/:id",
  OTPCONFIRMATION:"/home/otpconfirmation"

 
};

export { routes };
