import { message } from "antd";
import { setStorageItem } from "../../../infrastructure/common/local";
import { onLogin } from "../../../infrastructure/login";
import { routes } from "../../common/Routes/routes";
import { logError } from "../../common/Utils";

const actions = {
  setToken:
    (newValue) =>
    ({ setState }) => {
      setState({ token: newValue });
    },
  setIsLogged:
    (newValue) =>
    ({ setState }) => {
      setState({ isLogged: newValue });
    },
  onSubmit:
    (values, history) =>
    async ({ dispatch }) => {
      const formdata = { ...values };
      var form_data = new FormData();
      for (var key in formdata) {
        form_data.append(key, formdata[key]);
      }
      try {
        const res = await onLogin(form_data);
        setStorageItem("token", res?.data?.access);
        dispatch(actions.setToken(res?.data?.access));
        console.log(res, "hjdsz");
        if (res?.data?.access) {
          message.success("You are successfully logged in");

          // alert(res.StatusCode)
          history.push(routes.DASHBOARD);
        } else {
          message.error("please input your username or password");
        }
      } catch (error) {
        logError("login", error);
      }
    },
};

export default actions;
