import { get } from "../common/remote/base_api";

export const getSosList= () => {
    return get(`/sos/view-all/`);
  };

  export const getSosSingle= (id) => {
    return get(`/sos/single-view/${id}/`);
  };

  export const getCountryList= () => {
    return get(`/general/avalibale-country/`);
  };