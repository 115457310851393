import axios from "axios";
import config from "../../../common/config";
import { logError } from "../../../presentation/common/Utils";
import { getStorageItem } from "../local";
import { errorMessage } from "../utils";

const baseURL = process.env.REACT_APP_BASE_API;
console.log(baseURL,"hhhhj")
const base = async (options) => {
  const token = await getStorageItem("token").then((result) => result);

  const res = await axios({
    baseURL,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    ...options,
  }).catch((err) => {
    let error = err?.response?.data?.errors;
    errorMessage(error);
    logError(error);
  });
  return res.data;
};

const noTokenBase = async (options) => {
  const res = await axios({
    baseURL,
    headers: {
      Accept: "application/json",
    },
    ...options,
  }).catch((err) => {
    let error = err?.response?.data?.errors;
    errorMessage(error);
    logError(error);
  });
  return res.data;
};

export const get = (url, params) => {
  const options = {
    method: "get",
    url,
    params,
  };
  return base(options);
};

export const patch = (url, data) => {
  const options = {
    method: "patch",
    url,
    data,
  };
  return base(options);
};

export const noToken = (url, data) => {
  const options = {
    method: "post",
    url,
    data,
  };
  return noTokenBase(options);
};

export const post = (url, data) => {
  const options = {
    method: "post",
    url,
    data,
  };
  return base(options);
};

export const put = (url, data) => {
  const options = {
    method: "put",
    url,
    data,
  };
  return base(options);
};

export const del = (url, data) => {
  const options = {
    method: "delete",
    url,
    data,
  };
  return base(options);
};
