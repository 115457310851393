import { message } from "antd";
import { getStorageItem } from "../local";

// Common utilites
export const errorMessage = (error) => {
  if (Array.isArray(error) === true) {
    error?.forEach((element) => {
      message.error(`${element}`);
    });
  } else if (typeof error === "string") {
    message.error(error);
  } else {
    for (const [key, value] of Object.entries(error)) {
      message.error(`${key} : ${value}`);
    }
  }
};

export const getIcanUserLocal = () => {
  return getStorageItem("ican_user");
};
