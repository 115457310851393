import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSkillStore } from "../store";
import Moment from "react-moment";



export default function RequestExpanded(props) {
  const { id } = useParams();
  console.log(id, "hiii");
  let history = useHistory();

  const [
    { isLoading, requestList, searchAllRequest },
    { getListSingle, getList,onSubmit ,onReject},
  ] = useSkillStore();
  console.log(requestList, "llooo");
  // console.log(requestList,"hjk")
 
  useEffect(() => {
    getListSingle(id);

  }, []);
  return (
    <div id="request-exp" >
      <div className="heading">
        <div>
          <h1>SOS Request</h1>
          <h2>SOS Request -{requestList.auto_id}</h2>
        </div>
        <div style={{display:"flex"}}>
        <div  style={{marginRight:"20px"}}>
          <button onClick={() =>
            onSubmit(id,history)}
          >Accept Request</button>
        </div>
        <div>
          <button onClick={() =>
            onReject(id,history)}
          >Reject Request</button>
        </div>
      
        </div>
      
      </div>

        <div>
          <span className="section">Details</span>
          <div style={{ float: "right", width: "88%", marginTop: "-18px" }}>
            <hr
              style={{
                border: "1px solid rgba(255, 255, 255, 0.16)",
              }}
            />
          </div>
      

          <div className="req-details">
          <div className="requests">
            <span>Request number</span>
            <label>{requestList.auto_id}</label>
          </div>

          <div className="requests">
            <span>Case type</span>
            <label className="btn">{requestList.sos_type_name}</label>
          </div>



          
          <div className="requests">
            <span>Date</span>
            <label>
            
            <Moment format="YYYY-MM-DD , HH:mm">{requestList.date_added}</Moment>
            </label>
          </div>

          <div className="requests">
            <span>First Name & Lastname</span>
            <label>{requestList.name}</label>
          </div>
          <div className="requests">
            <span>Vehicle Number</span>
            <label>{requestList.vehicle_registration}</label>
          </div>

          
          <div className="requests">
            <span>Brand and Model</span>
            <label>{requestList.brand }  &#160;  { requestList.model}</label>
          </div>
        

          <div className="requests">
            <span>Location</span>
            <label >{requestList.address}</label>
          </div>

          <div className="requests">
            <span>Mobile number</span>
            <label>{requestList.phone_number}</label>
          </div>

          <div className="requests">
            <span>Email</span>
            <label>{requestList.email}</label>
          </div>

          <div className="requests">
            <span>Vehicle Issue</span>
            <label>{requestList.vehicle_issue}</label>
          </div>

          <div className="requests">
            <span>Comment</span>
            <label>{requestList.reason}</label>
          </div>
        </div>
      

        </div>
    </div>
  );
}
