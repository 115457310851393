import { logError } from "../../common/Utils";
import { message } from "antd";
import {
  getCountryList,
  getSosList,
  getSosSingle,
} from "../../../infrastructure/request";
import {
  onAccept,
  onCompleteRequest,
  onReject,
  onVerify,
  onVerifyOtp,
  onResendOtp,
} from "../../../infrastructure/requestexpanded";

import { routes } from "../../common/Routes/routes";
import { setStorageItem } from "../../../infrastructure/common/local";

const actions = {
  setVisibleOtp:
    (params) =>
    ({ setState }) => {
      setState({ visibleOtp: params });
    },
  setVisible:
    (params) =>
    ({ setState }) => {
      setState({ visible: params });
    },
  getList:
    () =>
    async ({ setState }) => {
      try {
        // setState({ isLoading: true });
        const res = await getSosList();
        console.log(res, "hh");
        setState({ request: res.data });
        setState({ searchAllRequest: res.data });
        setState({ isLoading: false });
      } catch (error) {
        logError(error);
        setState({ isLoading: false });
      }
    },
  setSingleList:
    (value) =>
    ({ setState }) => {
      setState({ requestList: value });
    },

  setViewId:
    (value) =>
    ({ setState }) => {
      setState({ viewId: value });
    },

  getCountryList:
    () =>
    async ({ setState }) => {
      try {
        // setState({ isLoading: true });
        const res = await getCountryList();
        console.log(res, "ll");
        setState({ countryList: res.data });
      } catch (error) {
        logError(error);
        setState({ isLoading: false });
      }
    },

  getListSingle:
    (id) =>
    async ({ setState }) => {
      console.log(id, "hvb");

      try {
        // setState({ isLoading: true });
        const res = await getSosSingle(id);
        console.log(res, "ll");
        setState({ requestList: res.data });
        setState({ searchAllRequest: res.data });
        setState({ isLoading: false });
      } catch (error) {
        logError(error);
        setState({ isLoading: false });
      }
    },

  onConfirmOtp:
    (values) =>
    async ({ dispatch, setState }) => {
      console.log(values, "oo");
      const formdata = { ...values };
      var form_data = new FormData();
      for (var key in formdata) {
        form_data.append(key, formdata[key]);
      }
      try {
        const res = await onVerifyOtp(form_data);
        console.log(res, "tok");
        setState({ otpverified: res.data.title });

        if (res?.data?.title === "Success") {
          message.success(" OTP verified successfuly");
        } else {
          message.error("Please Generate New OTP");
          dispatch(actions.setVisibleOtp(false));
        }
      } catch (error) {
        logError("login", error);
      }
    },

  onSubmitPhone:
    (values) =>
    async ({ dispatch, setState }) => {
      console.log(values, "koo");
      const formdata = { ...values };
      var form_data = new FormData();
      for (var key in formdata) {
        form_data.append(key, formdata[key]);
      }
      try {
        const res = await onVerify(form_data);
        console.log(res, "token");

        if (res?.data?.otp_code) {
          message.success("otp sent to customer");
          dispatch(actions.setVisible(false));

          dispatch(actions.setVisibleOtp(true));
        } else {
          message.error("please check  country or phone number");
        }
      } catch (error) {
        logError("login", error);
      }
    },

  onSubmit:
    (id, history) =>
    async ({ setState, dispatch }) => {
      console.log(id, "jjj");
      try {
        setState({ isLoading: true });
        await onAccept(id);

        // dispatch(actions.setNewVisible(false));
        history.push(routes.REQUEST);

        message.success("SOS Request Accepted");

        // form.resetFields();
        setState({ isLoading: false });

        // dispatch(actions.getAllSkill());
      } catch (error) {
        logError(error);
        setState({ isLoading: false });
      }
    },

  onReject:
    (id, history) =>
    async ({ setState, dispatch }) => {
      console.log(id, "jjj");
      try {
        setState({ isLoading: true });
        await onReject(id);
        setState({ isLoading: false });

        // dispatch(actions.setNewVisible(false));

        history.push(routes.REQUEST);
        message.success("SOS Request Rejected");

        // form.resetFields();
        setState({ isLoading: false });
        // dispatch(actions.getAllSkill());
      } catch (error) {
        logError(error);
        setState({ isLoading: false });
      }
    },

  onComplete:
    (id, history) =>
    async ({ setState, dispatch }) => {
      console.log(id, "jjj");
      try {
        setState({ isLoading: true });
        await onCompleteRequest(id);
        setState({ isLoading: false });

        // dispatch(actions.setNewVisible(false));
        history.push(routes.REQUEST);
        message.success("SOS Request Completed");

        dispatch(actions.setVisibleOtp(false));

        setState({ isLoading: false });
        // dispatch(actions.getAllSkill());
      } catch (error) {
        logError(error);
        setState({ isLoading: false });
      }
    },

  onResend:
    (values) =>
    async ({ dispatch, setState }) => {
      console.log(values, "resent");
      const formdata = { ...values };
      var form_data = new FormData();
      for (var key in formdata) {
        form_data.append(key, formdata[key]);
      }
      try {
        const res = await onResendOtp(form_data);
        console.log(res.data, "resent succesful");

        message.success("otp resend successfully");
        // dispatch(actions.setVisible(false));

        // dispatch(actions.setVisibleOtp(true));
      } catch (error) {
        logError("login", error);
      }
    },

  onHandleSearch:
    (searchText) =>
    ({ getState, setState }) => {
      const data = getState().request?.filter(
        (item) =>
          item?.reason?.toUpperCase().includes(searchText.toUpperCase()) ||
          item?.phone_number
            ?.toUpperCase()
            .includes(searchText.toUpperCase()) ||
          item?.status?.toUpperCase().includes(searchText.toUpperCase())
      );
      setState({ request: data });
    },
};

export default actions;
